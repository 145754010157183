import React, { useEffect } from 'react';
import { Layout, Timeline } from 'antd';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import eventsES from '../content/events_es.json';
import eventsZH from '../content/events_zh.json';
import './HistoryTimeline.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const HistoryTimeline = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { i18n } = useTranslation();
  const events = i18n.language === 'es' ? eventsES : eventsZH;

  return (
    <div>
      <SEOHeader titleKey='history_timeline' descriptionKey='history_timeline' />
      <Layout className="history-timeline-layout">
        <BackgroundImage title='history_timeline' bgImgClass="history-timeline-bg-image" />
        <div className="timeline-container">
          <Timeline mode="left">
            {events.map((event, index) => (
              <Timeline.Item
                key={index}
                label={
                  event.month ?
                    <span className="timeline-month">{event.month}</span> :
                    <span className="timeline-year">{event.year}</span>
                }
                dot={event.month ? <div className="month-circle" /> : <div className="year-circle" />}
              >
                {event.month ? <span className='description'>{event.description}</span> : event.description}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </Layout>
    </div>
  );
};

export default HistoryTimeline;
