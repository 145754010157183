import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';
import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import './LanguageSwitcher.css'; // Assuming you create a CSS file for styles
import { Button, Space } from 'antd'; // Import Button and Space from antd

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  const handleChange = (value) => {
    const currentDomain = window.location.hostname;
    let newDomain;

    if (value === LOCALE_ES) {
      newDomain = process.env.REACT_APP_BASE_URL_ES;
    } else if (value === LOCALE_ZH) {
      newDomain = process.env.REACT_APP_BASE_URL_ZH;
    }

    if (newDomain && currentDomain !== newDomain) {
      window.location.href = `${newDomain}${window.location.pathname}`;
    }
  };

  const items = [
    {
      key: LOCALE_ES,
      label: 'ES',
    },
    {
      key: LOCALE_ZH,
      label: '中文',
    },
  ];

  const menu = {
    items: items.map(item => ({
      key: item.key,
      label: (
        <a onClick={() => handleChange(item.key)} className="language-option">
          {item.label}
        </a>
      ),
    })),
  };

  return (isMobile ?
    (<Space>
      <Button
        className={`language-button ${currentLang === LOCALE_ES ? 'active' : ''}`}
        onClick={() => handleChange(LOCALE_ES)}
        icon={<GlobalOutlined />}
      >
        ES
      </Button>
      <Button
        className={`language-button ${currentLang === LOCALE_ZH ? 'active' : ''}`}
        onClick={() => handleChange(LOCALE_ZH)}
        icon={<GlobalOutlined />}
      >
        中文
      </Button>
    </Space>) : (<Dropdown menu={menu} trigger={['click']}>
      <div className="language-switcher">
        <GlobalOutlined className="language-icon" />
        <span className="language-current">
          {currentLang === LOCALE_ES ? 'ES' : '中文'}
        </span>
      </div>
    </Dropdown>)
  );
};

export default LanguageSwitcher;
