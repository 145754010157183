import React, { useEffect } from 'react';
import { Layout, Card } from 'antd';
import { useTranslation } from 'react-i18next'; 
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import historyContentZh from '../content/historyContentZh.json'; 
import historyContentEs from '../content/historyContentEs.json'; 
import './History.css'; 
import { useLocation } from 'react-router-dom';

const History = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { i18n } = useTranslation();
  const currentLang = i18n.language || 'es'; 
  const content = currentLang === 'es' ? historyContentEs.sections : historyContentZh.sections;

  return (
    <div>
      <SEOHeader titleKey='history' descriptionKey='history' />
      <Layout className="history-layout">
        <BackgroundImage title='history' bgImgClass="history-bg-image"/>
        <div className="history-card-container">
          <Card className="history-card">
            <h1>{i18n.t('icce_origin')}</h1> 
            {content.map((section, index) => (
              <div key={index} className="history-section">
                <p>{section.text}</p>
                {section.image && <img src={section.image} alt={`Image ${index + 1}`} className="history-image" />}
              </div>
            ))}
          </Card>
        </div>
      </Layout>
    </div>
  );
};

export default History;
