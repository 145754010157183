// src/components/PrayerRequests.js
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomCarousel from './CustomCarousel'; // Importar el nuevo componente
import RichTextRenderer from './RichTextRenderer'; // Importar el componente RichTextRenderer
import { fetchPrayerRequests } from '../axiosInstance';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';

const PrayerRequests = () => {
  const { i18n, t } = useTranslation();
  const [prayerRequests, setPrayerRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  const fetchPrayerRequestsData = async () => {
    try {
      let fetchedData = await fetchPrayerRequests(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchPrayerRequests(LOCALE_ZH);
      }
      if (fetchedData) {
        setPrayerRequests(fetchedData);
      } else {
        console.error('Error fetching church requests');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching church requests:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPrayerRequestsData();
  }, []);

  return loading ? (
    <Spin size="large" />
  ) : (
    <CustomCarousel
      title={t('prayer_requests')} // Título del carrusel
      items={prayerRequests} // Elementos del carrusel
      enableReadMore={true}
      detailUrl={"prayer-request"}
      renderItem={(request) => (
        <div key={request.id} className="prayer-request">
          <h3>{request.attributes.title}</h3>
          <RichTextRenderer content={request.attributes.content} /> {/* Renderizar contenido con RichTextRenderer */}
        </div>
      )}
    />
  );
};

export default PrayerRequests;
