import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import SEOHeader from '../components/SEOHeader';
import DailyDevotion from '../components/DailyDevotion';
import PrayerRequests from '../components/PrayerRequests';
import ChurchRequests from '../components/ChurchRequests';
import BackgroundImage from '../components/BackgroundImage';
import Donation from '../components/Donation';
import './Home.css';

const Home = () => {
  const location = useLocation();
  const donationRef = useRef();
  const churchRequestsRef = useRef();
  const scrollToComponent = (smooth = false) => {
    const scrollToElement = (elementRef, offset) => {
      const elementPosition = elementRef.current.getBoundingClientRect().top + window.scrollY;
      const currentScrollPosition = window.scrollY;
      const offsetPosition = currentScrollPosition > elementPosition ? elementPosition - offset : elementPosition;
      window.scrollTo({
        top: offsetPosition,
        behavior: smooth ? 'smooth' : 'instant'
      });
    };
  
    setTimeout(() => {
      if (location.hash === "#donation-section" && donationRef.current) {
        scrollToElement(donationRef, 70);
      } else if (location.hash === "#church-requests-section" && churchRequestsRef.current) {
        scrollToElement(churchRequestsRef, 85);
      } else {
        window.scrollTo(0, 0); 
      }
    }, 100);
  };
  
  useEffect(() => scrollToComponent(true), [location])
  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <Layout className="home-layout">
        <BackgroundImage title='slogan' bgImgClass="home-bg-image" />
        <DailyDevotion />
        <PrayerRequests/>
        <div id="church-requests-section" ref={churchRequestsRef}></div>
        <ChurchRequests/>
        <div id="donation-section" ref={donationRef}></div>
        <Donation />
      </Layout>
    </div>
  );
};

export default Home;
