// src/axios.js
import axios from 'axios';
import { LOCALE_ES, LOCALE_ZH, LOCALE_ZH_HANS } from './i18n';
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const instance = axios.create({
  baseURL: BASE_URL, // URL base de tu API de Strapi
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`, // Token de autenticación
  },
});

export default instance;


export const getLocaleFilterAndRequest = (currentLang, requestId) => {
  let localeFilter = '';
  if (currentLang === LOCALE_ES) {
    localeFilter = 'locale=es&';
    requestId = requestId + '-es';
  }
  return { localeFilter, requestId };
};

export const getLocaleFilter = (currentLang) => {
  return currentLang === LOCALE_ES ? 'locale=es&' : 'locale=zh-Hans-CN&';
};

export const fetchChurch = async (lang, churchId) => {
  const { localeFilter, requestId } = getLocaleFilterAndRequest(lang, churchId);

  try {
    const response = await instance.get(
      `/churches?${localeFilter}filters[uid][$eq]=${requestId}&populate=churchPic,informationPic&populate[1]=contactInfo.meetingTime`
    );
    return response.data.data[0]; // Retornamos el primer resultado
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchChurchRequests = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/church-requests?${localeFilter}`
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchPrayerRequests = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/prayer-requests?${localeFilter}`
    );
    return response.data.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchChurchRequestDetails = async (lang, churchRequestId) => {

  try {
    const response = await instance.get(
      `/church-requests/${churchRequestId}?populate=*`
    );
    const data = response.data.data.attributes;
    if (lang === LOCALE_ZH) lang = LOCALE_ZH_HANS;
    if (data?.locale === lang) {
      return data;
    }
    return data.localizations.data[0]?.attributes;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};

export const fetchPrayerRequestDetails = async (lang, requestId) => {
  try {
    const response = await instance.get(
      `/prayer-requests/${requestId}?populate=*`
    );
    const data = response.data.data.attributes;
    if (lang === LOCALE_ZH) lang = LOCALE_ZH_HANS;
    if (data?.locale === lang) {
      return data;
    }
    return data.localizations.data[0]?.attributes;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};


export const fetchDailyReading = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  const today = new Date();
  const formattedDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

  try {
    const response = await instance.get(
      `/readings?${localeFilter}filters[publishDate][$eq]=${formattedDate}`
    );
    return response.data.data[0]; // Retornamos el primer resultado
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching church data for locale ${lang}:`, error);
    throw error; // Lanzamos el error para que el componente lo maneje
  }
};

export const fetchFullTimePastors = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/our-pastor?populate=fullTimePastors&populate[2]=fullTimePastors.picture&${localeFilter}`
    );
    return response.data.data.attributes.fullTimePastors;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching fetchFullTimePastors for locale ${lang}:`, error);
    throw error; // Seguimos lanzando el error en otros casos
  }
};


export const fetchOrganizationalStructure = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/organizational-structure?populate=standingCommittee,council&populate[2]=standingCommittee.picture,council.picture&${localeFilter}`
    );
    return response.data.data.attributes;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching fetchFullTimePastors for locale ${lang}:`, error);
    throw error; // Seguimos lanzando el error en otros casos
  }
};


export const fetchFaith = async (lang) => {
  const localeFilter = getLocaleFilter(lang);
  try {
    const response = await instance.get(
      `/faith-declaration?populate=list&${localeFilter}`
    );
    return response.data.data.attributes.list;
  } catch (error) {
    if (error?.response?.status === 404) {
      console.warn(`No data found for locale ${lang}. Returning null.`);
      return null; // Retornamos null para que lo maneje el componente
    }
    console.error(`Error fetching fetchFullTimePastors for locale ${lang}:`, error);
    throw error; // Seguimos lanzando el error en otros casos
  }
};