import React, { useEffect, useState } from 'react';
import { Layout, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import apostlesCreedEs from '../content/apostles_creed_es.json';
import apostlesCreedZh from '../content/apostles_creed_zh.json';
import niceneCreedEs from '../content/nicene_creed_es.json';
import niceneCreedZh from '../content/nicene_creed_zh.json';
import './FaithDeclaration.css';
import { useLocation } from 'react-router-dom';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';
import { fetchFaith } from '../axiosInstance';
import RichTextRenderer from '../components/RichTextRenderer';
export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const FaithDeclaration = () => {
  const { pathname } = useLocation();
  const [creedsArray, setCreedsArray] = useState([]);
  const { t, i18n} = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchFaithData = async () => {
    try {
      let fetchedData = await fetchFaith(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchFaith(LOCALE_ZH);
      }
      if (fetchedData) {
        setCreedsArray(fetchedData);
      }
    } catch (error) {
      console.error('Error fetching church requests:', error);
    }
  };
  
  useEffect(() => {
    fetchFaithData();
  }, []);

  return (
    <div>
      <SEOHeader titleKey='faith' descriptionKey='faith' />
      <Layout className="faith-layout">
        <BackgroundImage title='faith_declaration' bgImgClass="faith-bg-image" />
        {creedsArray.map((creed, index) => (
          <div key={index} className="faith-card-container">
            <Card className="faith-card">
              <h1>《{creed.title}》</h1>
              <div className="faith-section">
                <RichTextRenderer content={creed.content}></RichTextRenderer>
              </div>
            </Card>
          </div>
        ))}
      </Layout>
    </div>
  );
};

export default FaithDeclaration;
