// src/components/BackgroundImage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BackgroundImage.css'; // Importar CSS para el componente

const BackgroundImage = ({ title, bgImgClass }) => {
    const { t } = useTranslation();

    return (
        <div className={"background-image " + bgImgClass}>
            <div className="title-container">
                <h1 className="animated-title">{t(title)}</h1>
            </div>
        </div>
    );
};

export default BackgroundImage;
