import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import instance, { fetchChurch } from '../axiosInstance'; // Asegúrate de que esta instancia esté configurada correctamente
import { useTranslation } from 'react-i18next';
import { getLocaleFilter, LOCALE_ES } from '../i18n';
import './ChurchDetails.css';
import { Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import RichTextRenderer from '../components/RichTextRenderer';

export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const ChurchDetail = () => {
  const { churchId } = useParams();
  const [churchData, setChurchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { i18n, t } = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  const fetchChurchData = async () => {
    setLoading(true);
    setError(null);
    setChurchData(null);
    try {
      let fetchedData = await fetchChurch(currentLang, churchId);

      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchChurch('zh', churchId);
      }

      if (fetchedData) {
        setChurchData(fetchedData);
      } else {
        setError('No se encontró información de la iglesia.');
      }
    } catch (err) {
      setError('Error al cargar la información de la iglesia.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChurchData();
  }, [churchId]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  // Extraer atributos de la respuesta
  const { name, churchPic, information, contactInfo, informationPic, pastor, meetingTime } = churchData.attributes;

  // Formatear la información para mostrarla
  const informationText = <RichTextRenderer content={information} />;

  // Traducción de días de la semana
  const translatedDays = {
    monday: t('monday'),
    tuesday: t('tuesday'),
    wednesday: t('wednesday'),
    thursday: t('thursday'),
    friday: t('friday'),
    saturday: t('saturday'),
    sunday: t('sunday'),
  };

  // Formatear los horarios de reuniones
  const formatTime = (time) => {
    return time.slice(0, 5); // Esto corta los segundos, mostrando solo HH:mm
  };

  const renderMultilineText = (text, padding = '75px') => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span style={index > 0 ? { paddingLeft: padding } : {}}>
          {line}
        </span>
        <br />
      </React.Fragment>
    ));
  };


  return (
    <Layout className="church-detail">
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <h1><span className='icce-title'>{t('icce_title')}</span> <span className='divider'>|</span> {name}</h1>
      <div className="church-section church-section-two">
        {churchPic?.data && (
          <img
            src={`${BASE_URL}${churchPic.data.attributes.url}`}
            alt={name}
          />
        )}

        <div className="church-contact-info">
          {contactInfo.location && <p>{t('location')}: {contactInfo.location}</p>}
          {contactInfo.contactPerson && <p>{t('contact_person')}: {contactInfo.contactPerson}</p>}
          {contactInfo.phoneNumber && <p>{t('contact_phone')}: {contactInfo.phoneNumber}</p>}
          {pastor && (
            <p>
              {t('church_pastor')}: {renderMultilineText(pastor, currentLang === LOCALE_ES ? '72px' : '84px')}
            </p>
          )}

          {meetingTime && (
            <p>
              {t('meeting_time')}: {renderMultilineText(meetingTime, currentLang === LOCALE_ES ? '125px' : '84px')}
            </p>
          )}
        </div>
      </div>
      <div className="church-section church-section-three">
        {informationPic?.data ? (
          <>
            <img
              src={`${BASE_URL}${informationPic.data.attributes.url}`}
              alt="Información adicional"
            />
            <div className="church-information">
              <p>{informationText}</p>
            </div>
          </>
        ) : (
          <div className="church-information full-width">
            <p>{informationText}</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ChurchDetail;
