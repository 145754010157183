import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEOHeader = ({ titleKey, descriptionKey, keywordsKey }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const getManifestUrl = () => {
    if (currentLang === 'zh') {
      return "/manifest_zh.json";
    }
    return "/manifest_es.json";  // Default to Spanish
  };
  return (
    <Helmet>
      <html lang={currentLang} />
      <title>{t(titleKey)}</title>
      <meta name="description" content={t(descriptionKey)} />
      <meta name="keywords" content={t(keywordsKey)} />
      <meta property="og:title" content={t(titleKey)} />
      <meta property="og:description" content={t(descriptionKey)} />
      <link rel="manifest" href={getManifestUrl()} />
    </Helmet>
  );
};

export default SEOHeader;
