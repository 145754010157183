import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import './OrganizationalStructure.css';
import { useLocation } from 'react-router-dom';
import { fetchOrganizationalStructure } from '../axiosInstance';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';
export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const OrganizationalStructure = () => {
  const { pathname } = useLocation();
  const [standingCommittee, setStandingCommittee] = useState([]);
  const [council, setCouncil] = useState([]);
  const { t, i18n} = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchFullTimePastorsData = async () => {
    try {
      let fetchedData = await fetchOrganizationalStructure(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchOrganizationalStructure(LOCALE_ZH);
      }
      if (fetchedData.standingCommittee) {
        setStandingCommittee(fetchedData.standingCommittee);
      }
      if (fetchedData.council) {
        setCouncil(fetchedData.council);
      }
    } catch (error) {
      console.error('Error fetching church requests:', error);
    }
  };
  
  useEffect(() => {
    fetchFullTimePastorsData();
  }, []);

  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <BackgroundImage title='organizational_structure' bgImgClass="structure-bg-image" />
      <Layout className="structure-layout">
        <h2 className='structure-title'><span>{t('organization_period')}</span> <span className='divider'>|</span> {t('standing_committee')}</h2>
        <div className="structure-container">
          <Row gutter={[16, 16]} justify="space-around">
            {standingCommittee.map((structure, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={4}>
                <Card className="structure-card">
                  <img
                    src={structure.picture?.data?.attributes?.url? `${BASE_URL}${structure.picture?.data?.attributes?.url}` : '/user_icon.png'}
                    alt={structure.name}
                    className="structure-image"
                  />
                  <h2>{structure.jobTitle}</h2>
                  <h3>{structure.name}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <h2 className='structure-title'><span>{t('organization_period')}</span> <span className='divider'>|</span> {t('council')}</h2>
        <div className="structure-container no-image">
          <Row gutter={[16, 16]} justify="space-around">
            {council.map((structure, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <Card className="structure-card">
                  <h3>{structure.name}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Layout>
    </div>
  );
};

export default OrganizationalStructure;
