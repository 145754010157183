import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Layout } from 'antd';
import SEOHeader from '../components/SEOHeader';
import BackgroundImage from '../components/BackgroundImage';
import './OurPastors.css';
import { useLocation } from 'react-router-dom';
import { LOCALE_ES, LOCALE_ZH } from '../i18n';
import { fetchFullTimePastors } from '../axiosInstance';
export const BASE_URL = process.env.REACT_APP_MEDIA_URL;

const OurPastors = () => {
  const { pathname } = useLocation();
  const [fullTimePastors, setFullTimePastors] = useState([]);
  const { t, i18n} = useTranslation();
  const currentLang = i18n.language ? i18n.language : LOCALE_ES;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchFullTimePastorsData = async () => {
    try {
      let fetchedData = await fetchFullTimePastors(currentLang);
      if (!fetchedData && currentLang === LOCALE_ES) {
        fetchedData = await fetchFullTimePastors(LOCALE_ZH);
      }
      if (fetchedData) {
        setFullTimePastors(fetchedData);
      }
    } catch (error) {
      console.error('Error fetching church requests:', error);
    }
  };
  
  useEffect(() => {
    fetchFullTimePastorsData();
  }, []);
  
  return (
    <div>
      <SEOHeader titleKey='icce_title' descriptionKey='icce_title' />
      <BackgroundImage title='our_pastors' bgImgClass="our-pastors-bg-image" />
      <Layout className="our-pastors-layout">
        <h2 className='pastor-title'>{t('full_time_pastor')}</h2>
        <div className="pastors-container">
        <Row gutter={[{xs: 0, md: 8, lg: 16}, {xs: 8, md: 8, lg: 16}]} justify="space-evenly">
          {fullTimePastors.map((pastor, index) => (
              <Col key={index} xs={20} md={7} lg={7}>
                <Card className={`pastor-card ${currentLang === LOCALE_ES ? 'lang-es' : ''}`}>
                  <img
                    src={pastor.picture?.data?.attributes?.url? `${BASE_URL}${pastor.picture?.data?.attributes?.url}` : '/user_icon.png'}
                    alt={pastor.name}
                    className="pastor-image"
                  />
                  <h2>{pastor.name}</h2>
                  <h3>{pastor.church}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Layout>
    </div>
  );
};

export default OurPastors;
